@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
  background: #0c1d28;
  --rv-input-disabled-text-color: #68e77d;
}

.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.rv-circle__text {
  color: unset !important;
}

.rv-notice-bar {
  height: 100% !important;
  color: unset !important;
  background-color: unset !important;
  line-height: normal !important;
}

.rv-tabbar {
  height: 74px !important;
  box-sizing: border-box !important;
  box-shadow: 0px 2px 10px rgba(104, 231, 125, 0.2) !important;
  /* 添加阴影效果 */

  background-color: #0c1d28 !important;
  z-index: 100 !important;
}

.rv-tabbar-item--active {
  background-color: #0c1d28 !important;
}

.rv-tabbar-item {
  justify-content: start !important;
}

.copy {
  opacity: 0 !important;
  position: absolute;
  top: 0;
  z-index: -999;
}

.rv-input__control {
  color: unset !important;
}

.rv-input {
  box-sizing: border-box;
  line-height: normal;
}

.rv-input__control::placeholder {
  color: #999999 !important;
  font-weight: normal;
  font-size: unset !important;
}

.rv-radio__label {
  color: white !important;
  font-size: 14px;
}

.rv-radio__icon--checked .rv-icon {
  background-color: #68e77d !important;
  border-color: #68e77d !important;
}

.rv-popup {
  background-color: transparent !important;
}
.rv-loading {
  background-color: #0c1d28;
  border-radius: 0.94rem;
}

.rv-toast.rv-popup {
  background-color: #0c1d28 !important;
  border-radius: 0.94rem !important;
}

.rv-swiper {
  height: 30px !important;
}

.rv-image__loading {
  opacity: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  50% {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  100% {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}
